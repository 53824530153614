import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import _ from 'lodash';

import { ProfileContext } from '../../contexts/ProfileContext';

import { Popups } from '../../components';
import GenericForm from '../../components/forms/genericForm';
import Loader from '../../components/common/Loader';

import {
  getProfessions,
  getRegions,
  updateProfileData,
  getFunction,
  getProfessionalAssociation,
  getSpecialJobTitles,
  getVocationalSchool
} from '../../service';

import { getSortedList, getTranslation } from '../../utils';
import { genericFormSchema } from '../../utils/schema';

export default function ChampionProfile() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [profile, setProfile] = useContext(ProfileContext);

  const [loading, setLoading] = useState(true);
  const [regions, setRegions] = useState([]);
  const [professions, setProfessions] = useState([]);
  const [listSwissRegions, setListSwissRegions] = useState([]);
  const [listProfessionalAssoc, setListProfessionalAssoc] = useState([]);
  const [listSwissFunction, setListSwissFunction] = useState([]);
  const [listSwissJobTitle, setListSwissJobTitle] = useState([]);
  const [swissJobTitleList, setSwissJobTitle] = useState([]);
  const [listVocationalSchool, setListVocationalSchool] = useState([]);
  const [missingFieldsError, setMissingFieldsError] = useState(false);
  const [unsavedChangesError, setUnsavedChangesError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [requiredFieldMissing, setRequiredFieldMissing] = useState([]);
  const [initialValues, setInitialValues] = useState({ ...profile });

  const {
    profileTypeData: { inputFields: fieldKeys } = {},
    masterData: { translationPrefix } = {}
  } = profile;

  const handleFormSubmit = async ({
    natFinalsRegion: { value: natFinalsRegion = '' } = {},
    worldSkillsProfession: { value: worldSkillsProfession = '' } = {},
    gender: { value: gender = '' } = {},
    natFinalsRank: { value: natFinalsRank = '' } = {},
    bestWorker: { value: bestWorker = '' } = {},
    bestApprentice: { value: bestApprentice = '' } = {},
    worldSkillsRank: { value: worldSkillsRank = '' } = {},
    euroSkillsRank: { value: euroSkillsRank = '' } = {},
    abilympicsProfession: { label: abilympicsProfession = '' } = {},
    region: { value: region = '' } = {},
    specialJobTitle: { value: specialJobTitle = '' } = {},
    profession: { value: profession = '' } = {},
    associationProfession: { value: associationProfession = '' } = {},
    vocationSchool: { value: vocationSchool = '' } = {},
    swissChampionRank: { value: swissChampionRank = '' } = {},
    participationType: { value: participationType = '' } = {},
    ...values
  }) => {
    try {
      let formData = {
        ...values,
        natFinalsRegion,
        worldSkillsProfession,
        gender,
        natFinalsRank,
        bestWorker,
        bestApprentice,
        worldSkillsRank,
        euroSkillsRank,
        abilympicsProfession,
        region,
        specialJobTitle,
        profession,
        associationProfession,
        vocationSchool,
        swissChampionRank,
        participationType
      };

      if (formData.functions) formData.functions = formData.functions.map((obj) => obj.value) || [];

      // Remove all values with falsy values
      formData = _.pickBy(formData, (value) => value || value === false);

      await updateProfileData(formData);
      setProfile({ ...formData, isUpdatedForPDF: true, validationReduested: false });
      toast.success(getTranslation(t, 'form.success', translationPrefix));
    } catch (e) {
      console.log(e);
      toast.error(getTranslation(t, 'form.fail', translationPrefix));
    }
  };

  const getPageData = async () => {
    try {
      const { data: regionData } = await getRegions(profile.masterOrganization);
      const { data: professionData } = await getProfessions(profile.masterOrganization);

      const filteredRegions = regionData.map((region) => ({
        value: region._id,
        label: region[`name_${i18n.language}`]
      }));

      const filteredProfessions = professionData.map((profession) => ({
        value: profession._id,
        label: profession[`jobRole_${i18n.language}`]
      }));

      setRegions(filteredRegions);
      setProfessions(filteredProfessions);

      // ch champion profile
      const currentLang = i18n.language;
      const { data: swissRegionData } = await getRegions(profile.masterOrganization);
      const { data: professionalAssocData } = await getProfessionalAssociation(
        profile.masterOrganization
      );
      const { data: swissFunctionData } = await getFunction(profile.masterOrganization);
      const { data: swissJobTitleData } = await getSpecialJobTitles(profile.masterOrganization);
      const { data: swissJobChampData } = await getProfessions(profile.masterOrganization);
      const { data: vocationalSchoolData } = await getVocationalSchool(profile.masterOrganization);

      const filteredSwissRegion = swissRegionData.map(({ _id, ...rest }) => ({
        value: _id,
        label: rest[`name_${currentLang}`]
      }));
      const filteredProfessionAssoc = professionalAssocData.map(({ name, _id }) => ({
        value: _id,
        label: name
      }));
      const filteredSwissFunction = swissFunctionData.map(({ _id, ...rest }) => ({
        value: _id,
        label: rest[`name_${currentLang}`]
      }));

      const isEN = currentLang === 'en';

      const filteredSwissJobTitle = getSortedList(
        swissJobTitleData,
        isEN ? 'de' : currentLang,
        'name'
      ).map(({ _id, ...rest }) => ({
        value: _id,
        label: rest[`name_${isEN ? 'de' : currentLang}`]
      }));

      const filteredSwissJobChamp = getSortedList(
        swissJobChampData,
        isEN ? 'de' : currentLang,
        'jobRole'
      ).map(({ _id, ...rest }) => ({
        value: _id,
        label: rest[`jobRole_${isEN ? 'de' : currentLang}`]
      }));

      const filteredVocationalSchool = vocationalSchoolData.map(({ name, _id }) => ({
        value: _id,
        label: name
      }));
      setSwissJobTitle(swissJobTitleData);
      setListSwissRegions(filteredSwissRegion);
      setListProfessionalAssoc(filteredProfessionAssoc);
      setListSwissFunction(filteredSwissFunction);
      setListSwissJobTitle(filteredSwissJobTitle);
      setProfessions(filteredSwissJobChamp);
      setListVocationalSchool(filteredVocationalSchool);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const getJobsList = async (gender) => {
    let filteredSwissJobTitle = [];
    if (gender === 'masculin') {
      filteredSwissJobTitle = swissJobTitleList.map((item) => ({
        value: item._id,
        label: item.male_de
      }));
    } else {
      filteredSwissJobTitle = swissJobTitleList.map((item) => ({
        value: item._id,
        label: item.female_de
      }));
    }
    setListSwissJobTitle(filteredSwissJobTitle);
  };

  const formSubmitHandler = async (values) => {
    try {
      let missingKeys = [...requiredFieldMissing];

      fieldKeys
        .filter(({ required }) => required)
        .forEach(({ field: { key } = {} }) => {
          if (values[key] === undefined || values[key] === null || values[key] === '') {
            if (!requiredFieldMissing.includes(key)) missingKeys.push(key);
          } else {
            missingKeys = missingKeys.filter((item) => item !== key);
          }
        });

      setRequiredFieldMissing([...missingKeys]);

      if (missingKeys.length) {
        setMissingFieldsError(true);
        return;
      }

      setSubmitting(true);
      await handleFormSubmit(values);
      setRequiredFieldMissing([]);
      setMissingFieldsError(false);
    } catch (error) {
      console.log(error);
      toast.error(getTranslation(t, `form.error`, translationPrefix));
      setMissingFieldsError(false);
    } finally {
      setSubmitting(false);
      setUnsavedChangesError(false);

      try {
        const errorElement = document?.querySelector('[data-testid="error-text"]');
        if (errorElement)
          setTimeout(
            () =>
              errorElement?.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center'
              }),
            50
          );
      } catch (error) {
        console.log('Error scrolling to error element', error);
      }
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: genericFormSchema,
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: formSubmitHandler
  });

  useEffect(() => {
    getPageData();
    getJobsList(profile.gender);
  }, [profile]);

  return (
    <Popups.FullPagePopup
      onCloseCallback={(originalFunction) => {
        if (formik.dirty) {
          setUnsavedChangesError(true);
        } else {
          originalFunction();
        }
      }}>
      <div className="h-full w-full">
        <GenericForm
          profile={profile}
          fieldKeys={fieldKeys}
          regions={regions}
          professions={professions}
          listSwissRegions={listSwissRegions}
          listProfessionalAssoc={listProfessionalAssoc}
          listSwissFunction={listSwissFunction}
          listSwissJobTitle={listSwissJobTitle}
          listVocationalSchool={listVocationalSchool}
          handleFormSubmit={handleFormSubmit}
          orgPrefix={translationPrefix}
          formik={formik}
          initialValues={initialValues}
          setInitialValues={setInitialValues}
          requiredFieldMissing={requiredFieldMissing}
          submitting={submitting}
        />
      </div>
      {loading && <Loader transparent={true} />}

      {missingFieldsError && (
        <Popups.FieldsMissing
          close={() => setMissingFieldsError(false)}
          orgPrefix={translationPrefix}
        />
      )}

      {unsavedChangesError && (
        <Popups.UnsavedChanges
          close={() => {
            setUnsavedChangesError(false);
            navigate(-1);
          }}
          orgPrefix={translationPrefix}
          saveCallback={() => formik.handleSubmit()}
        />
      )}
    </Popups.FullPagePopup>
  );
}
